* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
  justify-content: flex-start;
}

main {
  transition: all 0.8s;
  margin-left: 180px;
  width: 100%;
  overflow: hidden;
}

nav {
  text-align: right;
  position: fixed;
  height: 100%;
  transition: all 0.8s;
  width: 180px;
  background: #f6f6f6;
}

.title {
  font-weight: 800;
  font-size: 24pt;
  padding: 1rem 2rem;
  color: #1d1d1dcc;
}

.comment-section {
  max-height: 478px;
  overflow-y: auto;
}
.comment-section::-webkit-scrollbar {
  width: 9px;
}
.comment-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(6, 6, 6, 0.3);
  border-radius: 10px;
}

.comment-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(108, 108, 108, 0.5);
}
.comment-box {
  background-color: #1d1d1dcc;
  display: "flex";
  border: 1px solid #3d3d3dcc;
  border-radius: 0.5rem;
  height: 100%;
  width: 250px;
  padding: 0.5rem;
  margin: 0.2rem;
}

.comment-form {
  width: 50%;
  margin: 0 auto;
  background-color: #1d1d1dcc;
  padding: 1rem;
  transition: all 0.8s;
  border: 1px solid #1d1d1dcc;
  border-radius: 0.5rem;
}
.comment-header {
  display: flex;
  justify-content: space-between;
  color: #757575;
  font-size: 11pt;
  margin-bottom: 0.2rem;
}
.comment-subtitle1 {
  font-size: 9pt;
  color: #424242;
}
.comment-subtitle2 {
  margin-top: 0.2rem;
  color: #757575;
}

.modalRoot {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.8);
  color: var(--font-primary-color);
}

.ark {
  font-size: 12pt;
  font-weight: 500;
  margin-left: 0.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fccc53;
}

.theadLink {
  cursor: pointer;
}

.suggestionBox {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 0 0 0.3rem 0.3rem;
  font-size: 11pt;
}
.search-address {
  color: #565151;
  background-color: rgb(63, 63, 63);
  border: 1px solid rgb(196, 196, 196);
  width: 100%;
  padding: 1.1rem;
  border-style: none none solid none;
  border-radius: 0.2rem 0 0 0;
  margin: 0.8rem 0 0 0;
}
.search-address:hover {
  background-color: rgb(69, 69, 69);
}
.suggestion-result {
  padding: 0.2rem 0.5rem;
}
.suggestion-result:hover {
  background-color: rgb(63, 63, 63);
  cursor: pointer;
}

.link {
  display: flex;
  color: #424242;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.link:hover {
background-color: #ffecb3;
  color: #212121;
  transition: all 0.5s;
}

.icon,
.link_text {
  font-size: 12pt;
  font-weight: 300;
}

.active {
  background: #fbc02d;
  color: #000;
}

.bars:hover {
  cursor: pointer;
}

a {
  font-weight: 500;
  color: #555454;
  text-decoration: inherit;
}

a:hover {
  color: #212121;
  font-weight: 800;
}

.footer-section {
  position: fixed;
  bottom: 0;
  width: 180px;
  color: rgb(185, 155, 51);
  padding: 0.1rem;
}

.index-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-top: 12rem;
}

.landing-container {
  height: 100vh;
  margin: 0 auto;
  background: rgb(255, 255, 255);
}

.card:hover {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(213, 165, 20),
    0 0 20px rgb(213, 165, 20), 0 0 25px rgb(213, 165, 20),
    0 0 30px rgb(213, 165, 20), 0 0 35px rgb(213, 165, 20);

  cursor: pointer;
  transition: all 0.6s;
}

.index-footer {
  position: absolute;
  bottom: 0;
  color: #656565cc;
  padding: 0.5rem;
  width: 100vw;
  text-align: right;
}

.swiper {
  box-shadow: 0px 0px 20px #fff;
  border-radius: 1.1rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.1rem;
}

.custom-shape-divider {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider svg {
  position: relative;
  display: block;
  width: calc(173% + 1.3px);
  height: 125px;
}

.shape-fill {
  fill: #0f0f0f;
}

.bottom svg {
  position: relative;
  display: block;
  width: calc(200% + 1.3px);
  height: 151px;
}

@media screen and (min-width: 200px) and (max-width: 999px) {
  nav {
    width: 50px;
  }
  main {
    margin-left: 50px;
  }

  .link_text,
  .sidebar-footer {
    display: none;
  }
}
