@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply p-3
    }

    button {
        @apply text-white border bg-blue-500 border-blue-500
        hover:bg-transparent hover:text-amber-500 rounded-md
    }

    .nav {
        @apply font-mono tracking-[1px] relative ml-5 font-bold text-sm uppercase hover:text-gray-900 cursor-pointer 
    }
    .nav::after{
        @apply content-[''] bg-amber-400 h-[3px] w-[0%] absolute left-0 -bottom-[5px] rounded-xl duration-300
    }
    .nav:hover::after {
        @apply w-[100%]
    }
    
}